<template>
  <div v-if="ticketData">
    <b-card :title="$i18n.t('Ticket ID: #') + ticketData._id" v-if="ticketData">
      <div>
        <div>{{ $t("Nome completo") }}:</div>
        <h5>{{ ticketData.user.fullname }}</h5>
      </div>
      <div>
        <div>{{ $t("Usuário") }}: </div>
        <h5>{{ ticketData.user.username }}</h5>
      </div>
      <div>
        <div>{{ $t("E-mail") }}: </div>
        <h5>{{ ticketData.user.email }}</h5>
      </div>
      <div>
        <div>{{ $t("Saldo") }}: </div>
        <h5>{{ moneyFormat(ticketData.user.balance) }}</h5>
      </div>
      <div>
        <div>{{ $t("Upline") }}: </div>
        <h5>{{ ticketData.user.upline.fullname }} | {{
          ticketData.user.upline.email }}</h5>
      </div>
      <div>
        <div>{{ $t("Status") }}:</div>
        <h5>{{ $t(ticketData.status) }}</h5>
      </div>
      <div>
        <div>{{ $t("Assunto") }}:</div>
        <h5>{{ ticketData.subject }}</h5>
      </div>
      <div>
        <div>{{ $t("Descrição do problema") }}:</div>
        <h5>{{ ticketData.description }}</h5>
      </div>
      <div>
        <div>{{ $t("Categoria") }}:</div>
        <h5>{{ $t(ticketData.category) }}</h5>
      </div>
      <div v-if="ticketData.attachments.length > 0">
        <div>{{ $t("Imagem anexada") }}:</div>
        <div class="text-center">
          <b-img :src="renderImgAvatar(ticketData.attachments[0])" class="mt-1" />
        </div>
      </div>
    </b-card>
    <b-card :title="$i18n.t('Histórico da conversa')">
      <div v-for="(conversation, index) in ticketData.conversation" :key="index">
        <b-card :bg-variant="conversation.user.attendentId == returnUser._id
            ? 'light-warning'
            : 'light-info'
          ">
          <div class="d-flex flex-grow-1 align-items-center">
            <b-avatar size="32" :src="renderImgAvatar(conversation.user.avatar)"
              :text="avatarText(conversation.user.username)" class="mr-1" />
            <strong class="mr-auto text-white">{{
              conversation.user.username
            }}</strong>
            <small class="text-white">{{
              format(
                new Date(conversation.user.createdAt),
                "dd/MM/yyyy HH:mm:ss"
              )
            }}</small>
            <b-button 
              v-if="conversation.user.attendentId == returnUser._id" 
              variant="link" 
              size="sm" 
              class="ml-2 p-0 text-white"
              @click="startEditing(conversation, index)"
              v-b-tooltip.hover 
              :title="$t('Editar mensagem')"
              :disabled="isEditing"
            >
              <feather-icon v-if="editingIndex !== index" icon="EditIcon" size="16" />
              <b-spinner v-else small></b-spinner>
            </b-button>
          </div>
          <div class="mt-1 mx-1" v-if="editingIndex !== index">
            {{ conversation.response }}
            <small v-if="conversation.edited" class="text-muted d-block mt-1">
              <i>{{ $t('Editado') }} {{ conversation.editedAt ? format(new Date(conversation.editedAt), "dd/MM/yyyy HH:mm:ss") : '' }}</i>
            </small>
          </div>
          <div class="mt-1 mx-1" v-else>
            <b-form-textarea 
              v-model="editedMessage" 
              rows="3"
              :placeholder="$t('Edite sua mensagem')"
            ></b-form-textarea>
            <div class="d-flex mt-1">
              <b-button 
                variant="primary" 
                size="sm" 
                class="mr-1"
                @click="saveEdit()"
                :disabled="!editedMessage.trim()"
              >
                {{ $t('Salvar') }}
              </b-button>
              <b-button 
                variant="outline-secondary" 
                size="sm"
                @click="cancelEdit"
              >
                {{ $t('Cancelar') }}
              </b-button>
            </div>
          </div>
        </b-card>
      </div>
      <div v-if="ticketData.status == 'Open'">
        <hr />
        <div class="d-flex">
          <div class="d-flex flex-column w-100">
            <b-form-textarea id="problem-description" v-model="response"
              :placeholder="$i18n.t('Por favor, digite a sua resposta')" rows="3"></b-form-textarea>
            <small class="text-warning">É necessário preencher esse campo para responder ou fechar o
              ticket</small>
          </div>
          <!-- <div class="ml-2">
            <h6>
              {{ $t("Gostaria de anexar algum print?") }}
            </h6>
            <avatar-img
              :textNoImg="'I M G'"
              :rounded="'sm'"
              @avatarFile="(val) => (printFile = val)"
            />
          </div> -->
        </div>
        <div class="d-flex justify-content-start mt-1">
          <b-button class="mr-1" variant="outline-success" @click="() => responseTicket('Open')"
            :disabled="response.length == 0">{{ $t("Responder") }}</b-button>
          <b-button variant="outline-info" @click="() => responseTicket('Closed')" :disabled="response.length == 0">{{
            $t("Fechar ticket") }}</b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import { BFormTextarea, BButton, BTooltip } from "bootstrap-vue";
import { format } from "date-fns";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import api from "@/libs/axios"; // Import axios instance

export default {
  components: {
    BFormTextarea,
    BButton,
    BTooltip
  },
  data() {
    return {
      ticketData: null,
      response: "",
      editingIndex: -1,
      editedMessage: "",
      editingId: null,
      isEditing: false
    };
  },
  computed: {
    ...mapGetters(["returnUser"]),
  },
  mounted() {
    this.getTicketData();
  },
  methods: {
    getTicketData() {
      console.log('Fetching ticket data for ID:', this.$route.params.idTicket);
      
      this.$store
        .dispatch("getTicketSupportTeam", this.$route.params.idTicket)
        .then((resp) => {
          console.log('Received ticket data:', resp);
          this.ticketData = resp;
        })
        .catch(error => {
          console.error('Error fetching ticket data:', error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              variant: "danger",
              title: this.$t("Erro ao carregar dados do ticket")
            }
          });
        });
    },
    moneyFormat(value) {
      if (!value) return '$0.00';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(value);
    },
    renderImgAvatar(img) {
      if (img)
        return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
    },
    responseTicket(status) {
      const data = {
        ticketId: this.ticketData._id,
        response: this.response,
        status: status,
      };
      this.$store.dispatch("responseTicket", data).then((resp) => {
        this.getTicketData();
        this.response = "";
      });
    },
    startEditing(conversation, index) {
      // Make sure we're not already editing another message
      if (this.isEditing) {
        return;
      }
      
      // Log the entire conversation object to see its structure
      console.log('Conversation object:', JSON.stringify(conversation));
      
      this.editingIndex = index;
      this.editedMessage = conversation.response;
      
      // Try different approaches to get the message ID
      let messageId = null;
      
      // Approach 1: Direct _id property
      if (conversation && conversation._id) {
        messageId = conversation._id;
      }
      // Approach 2: Check if it's in a nested property
      else if (conversation && conversation.id) {
        messageId = conversation.id;
      }
      // Approach 3: Use the index as a fallback
      else {
        // Create a fallback ID using the index and timestamp
        messageId = `fallback-${index}-${Date.now()}`;
        console.warn('Using fallback message ID:', messageId);
      }
      
      // Convert to string if needed
      this.editingId = typeof messageId === 'object' && messageId.toString 
        ? messageId.toString() 
        : String(messageId);
      
      console.log('Using message ID:', this.editingId);
    },
    cancelEdit() {
      this.editingIndex = -1;
      this.editedMessage = "";
      this.editingId = null;
    },
    saveEdit() {
      if (!this.editedMessage.trim()) return;
      
      // Always use the index as the primary identifier
      const messageIndex = this.editingIndex;
      
      this.isEditing = true;
      
      // Get the conversation object directly
      const conversation = this.ticketData.conversation[messageIndex];
      
      console.log('Editing message at index:', messageIndex);
      console.log('Conversation object:', conversation);
      
      // Create a fallback ID using the index
      const messageIdToUse = `fallback-${messageIndex}-${Date.now()}`;
      
      console.log('Using message ID:', messageIdToUse);
      
      const data = {
        ticketId: this.ticketData._id,
        messageId: messageIdToUse,
        newMessage: this.editedMessage
      };
      
      console.log('Sending edit data:', data);
      
      // Use direct API call instead of Vuex store action
      api.patch('/support/edit-ticket-message', data)
        .then(response => {
          console.log('Edit response:', response.data);
          
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              variant: "success",
              title: this.$t("Mensagem atualizada com sucesso!")
            }
          });
          
          // Refresh the ticket data to ensure we have the latest version from the server
          this.getTicketData();
        })
        .catch(error => {
          console.error('Error editing message:', error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              variant: "danger",
              title: error && error.response && error.response.data && error.response.data.message 
                ? error.response.data.message 
                : this.$t("Erro ao atualizar mensagem")
            }
          });
        })
        .finally(() => {
          this.isEditing = false;
          this.cancelEdit();
        });
    }
  },
  setup() {
    format;

    return {
      avatarText,
      format,
    };
  },
};
</script>

<style scoped>
.feather-icon {
  vertical-align: middle;
}
</style>